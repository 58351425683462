export default {
  isAuthenticated: false,
  isAdmin: false,
  loading: false,
  token: null,
  user: {},
  expired: false,
  lastRoute: null,
  uf: process.env.VUE_APP_ESTADO,
  isSala: false,
  isTerritorio: false,
  logo: process.env.VUE_APP_LOGO,
  svg: process.env.VUE_APP_SVG_UF,
  tipoMapa: "Cidade Empreendedora",
  mapaFixoInicial: [],
  forcedLogout: false,
  config: null,
  mapa: {
    mapaFixo: [],
    totalMapaIndicadores: null,
    totalMapaMunicipio: null,
    totalMapaContratos: null,
    totalMapaRelacionamentos: null,
    totalMapaUrs: null,
    totalMapaEixos: null,
    totalMapaUfs: null,
    totalMapaTerritorio: null,
    totalMapaTerCategoria: null,
    totalMapaTerStatus: null,
    filter: {
      indicador: [],
      contratos: [],
      relacionamentos: [],
      municipio: {},
      uf: [],
      ur: [],
      territorio: [],
      terCategoria: [],
      terStatus: [],
    },
  },
};
